<template>
  <div class="time-line">
    <slot :test="this" />
  </div>
</template>

<script>
export default {
  name: "TimeLIne",
  props: {
    colorSetp: {
      type: String,
      default: "green"
    },
    colorBar: {
      type: String,
      default: "green"
    },
    colorGray: {
      type: String,
      default: "green"
    }
  },
  mounted() {
    this.$children.forEach(item => {
      item.colorSetp = this.colorSetp;
      item.colorTop = this.colorBar;
      item.colorBottom = this.colorBar;
      item.colorGray = this.colorGray;
    });
    let ant = null;
    this.$children.forEach((item, index) => {
      if (ant && ant.active && item.active) {
        ant.activeBottom = true;
      }
      ant = item;
      if (index + 1 == this.$children.length && item.active) {
        ant.activeBottom = true;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.time-line {
  margin: 10px;
}
</style>