<template>
  <div class="app">
    <div class="container pb-4 w-50">
      <b-row class="justify-content-center">
        <b-col md="12">
          <b-card class="bg-gray-default p-3">
            <h3>Dónde está mi envío</h3>
            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
            ></loading>
            <b-form @submit.prevent="onSearch">
              <b-form-group label-for="tracking">
                <b-form-input
                  id="trackingId"
                  class="w-100"
                  v-model="trackingId"
                  type="text"
                  placeholder="Introduce tu tracking id"
                  autocomplete="trackingId"
                ></b-form-input>
              </b-form-group>
              <b-button
                type="submit"
                class="float-right"
                variant="primary"
                :disabled="invalid"
                >Buscar</b-button
              >
            </b-form>
          </b-card>
        </b-col>
      </b-row>
      <!-- response -->
      <b-row class="justify-content-center" v-if="transaction">
        <b-col md="12">
          <b-card class="bg-gray-default p-3">
            <h4 v-if="transaction.retirement === 'client'">
              Tu envío será entregado en tu domicilio
            </h4>
            <h4 v-if="transaction.retirement === 'point'">
              Podrás retirar tu envío en el siguiente Punto de retiro
            </h4>
            <p>
              {{ transaction.direction }}
              <a
                v-if="transaction.retirement === 'point'"
                :href="
                  'https://www.google.com/maps?q=' +
                  transaction.retirement_addresses.latitude +
                  ',' +
                  transaction.retirement_addresses.longitude
                "
                target="_blank"
                class="pl-2"
              >
                Ver punto en Google Maps
              </a>
            </p>
            <b-card class="border border-secondary p-2" v-if="transaction.retirement_addresses">
              <p>Horario de atención (retiro)</p>
              <table class="table table-sm mt-3" style="width: 100%">
                <tr>
                  <th></th>
                  <th>Lunes</th>
                  <th>Martes</th>
                  <th>Miercoles</th>
                  <th>Jueves</th>
                  <th>Viernes</th>
                  <th>Sabado</th>
                  <th>Domingo</th>
                </tr>
                <tr>
                  <td>Apertura</td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[0].start | hour_format
                    }}
                  </td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[1].start | hour_format
                    }}
                  </td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[2].start | hour_format
                    }}
                  </td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[3].start | hour_format
                    }}
                  </td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[4].start | hour_format
                    }}
                  </td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[5].start | hour_format
                    }}
                  </td>
                  <td>
                    {{
                      transaction.retirement_addresses.schedules[6].start | hour_format
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Cierre</td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[0].end | hour_format }}
                  </td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[1].end | hour_format }}
                  </td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[2].end | hour_format }}
                  </td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[3].end | hour_format }}
                  </td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[4].end | hour_format }}
                  </td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[5].end | hour_format }}
                  </td>
                  <td>
                    {{ transaction.retirement_addresses.schedules[6].end | hour_format }}
                  </td>
                </tr>
              </table>
              <p>Nota: {{ transaction.retirement_addresses.note }}</p>
            </b-card>
            <TimeLineTracking :statuses="finalStatuses" />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Operationservice from "@/services/OperationService";
import TimeLineTracking from "@/layouts/components/TimeLineTracking";
import SettingService from "@/services/SettingService";

export default {
  name: "WShipment",
  components: { TimeLineTracking },
  data() {
    return {
      isLoading: false,
      trackingId: null,
      transaction: null,
      deonics: [],
      isClientToPoint: [],
      isClientToClient: [],
      isDepositToPoint: [],
      isDepositToClient: [],
    };
  },
  methods: {
    onSearch() {
      this.isLoading = true;
      this.transaction = null;
      Operationservice.timeline_tracking(this.trackingId)
        .then((response) => {
          this.transaction = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          const status = error.response.status;
          console.error(status);
          this.isLoading = false;
          if (status == 422) {
            const errors = error.response.data.errors;
            errors["tracking_id"].forEach((value) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `${value}`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
          } else if (error == 500) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: `Hubo un error al procesar la solicitud`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
  computed: {
    invalid: function () {
      return this.trackingId == null || this.trackingId.length == 0;
    },
    finalStatuses: function () {
      /*
      let defaultList = [];

      if (this.transaction.typeApi === 1) {
        defaultList = this.deonics;
      }

      if (this.transaction.typeApi === 2) {
        if (this.transaction.isClientToClient) {
          defaultList = this.isClientToClient;
        }
        if (this.transaction.isDepositToClient) {
          defaultList = this.isDepositToClient;
        }
        if (this.transaction.isClientToPoint) {
          defaultList = this.isClientToPoint;
        }
        if (this.transaction.isDepositToPoint) {
          defaultList = this.isDepositToPoint;
        }
      }
      let k = 0;
      let aux = [];
      for (let i = 0; i < defaultList.length; i++) {
        const itemA = defaultList[i];
        let find = false;
        for (let j = k; j < this.transaction.statuses.length; j++) {
          k = j + 1;
          const itemB = this.transaction.statuses[j];
          itemB.active = true;
          aux.push(itemB);
          if (itemA.id == itemB.status_id) {
            find = true;
            break;
          }
        }
        if (!find) {
          // Buscar si no esta duplicado
          if (aux.findIndex(d => d.status_id == itemA.id) === -1) {
            itemA.active = false;
            aux.push(itemA);
          }
        }
      }
      */
      return this.transaction.statuses.map((d) => {
        d.active = true;
        return d;
      });
    },
  },
  mounted() {
    SettingService.worm().then((response) => {
      const config = response.data.configuration;
      config.value.deonics.forEach((item) => {
        const value = response.data.deonics.find((t) => t.id == item);
        this.deonics.push(value);
      });

      config.value.unigis.isClientToPoint.forEach((item) => {
        const value = response.data.isClientToPoint.find((t) => t.id == item);
        this.isClientToPoint.push(value);
      });

      config.value.unigis.isClientToClient.forEach((item) => {
        const value = response.data.isClientToClient.find((t) => t.id == item);
        this.isClientToClient.push(value);
      });

      config.value.unigis.isDepositToPoint.forEach((item) => {
        const value = response.data.isDepositToPoint.find((t) => t.id == item);
        this.isDepositToPoint.push(value);
      });

      config.value.unigis.isDepositToClient.forEach((item) => {
        const value = response.data.isDepositToClient.find((t) => t.id == item);
        this.isDepositToClient.push(value);
      });

      if (this.$route.query.open) {
        this.trackingId = this.$route.query.open;
        this.onSearch();
      }
    });
  },
};
</script>
