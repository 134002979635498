<template>
  <div>
    <TimeLine :colorSetp="'#f68d1e'" :colorBar="'#f68d1e'" :colorGray="'#D3D3D3'">
      <TimeLineItem
        v-for="(item,index) in sortedItems"
        v-bind:key="item.id"
        :step="index + 1 "
        :active="item.active"
      >
        <b-card :title="item.title" class="mt-2" :style="{color: colorText(item) }">
          <span class="date-custom font-weight-bold" style="font-size:11px" v-if="item.fecha">
            <i class="icon-clock"></i>
            {{item.fecha | date_format('DD/MM/YYYY')}} - {{item.hora | hour_format}}
          </span>
          <p>{{ item.subtitle }}</p>
        </b-card>
      </TimeLineItem>
    </TimeLine>
  </div>
</template>


<script>
import { TimeLine, TimeLineItem } from "./timeline";

export default {
  name: "TimeLineTracking",
  props: {
    statuses: Array
  },
  components: {
    TimeLine,
    TimeLineItem
  },
  data() {
    return {};
  },
  methods: {
    colorText(item) {
      return item.active ? "#000" : "#696969";
    }
  },
  computed: {
    sortedItems: function() {
      return this.statuses;
    }
  },
  mounted() {}
};
</script>
<style>
.timeline-others {
  color: white;
  width: 35px;
  height: auto;
  top: 3em;
  left: -48px;
  padding: 5px 1px;
  text-align: center;
  font-weight: bold;
  border: none;
  background-color: var(--timelineBg);
}

.date-custom {
  float: right;
}

@media screen and (max-width: 600px) {
  .date-custom {
    float: unset;
  }
}
</style>