<template>
  <div class="time-line-item">
    <div class="item-step">
      <div class="top" :style="colorTopStyle"></div>
      <div class="step" name="step" :style="colorSetpStyle">{{ step }}</div>
      <div class="bottom" :style="colorBottomStyle"></div>
    </div>
    <div class="item">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeLineItem",
  data() {
    return {
      activeBottom: false,
      colorSetp: "green",
      colorTop: "green",
      colorBottom: "green",
      colorGray: "gray"
    };
  },
  props: {
    step: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorSetpStyle: function() {
      if (this.active) {
        return {
          "--color-step": this.colorSetp
        };
      }
      return {
        "--color-step": this.colorGray
      };
    },
    colorTopStyle: function() {
      if (this.active) {
        return {
          "--color-top": this.colorTop
        };
      }
      return {
        "--color-top": this.colorGray
      };
    },
    colorBottomStyle: function() {
      if (this.activeBottom) {
        return {
          "--color-bottom": this.colorBottom
        };
      }
      return {
        "--color-bottom": this.colorGray
      };
    }
  }
};
</script>

<style lang="scss">
.time-line-item {
  display: flex;
  min-height: 100px;

  .top {
    margin-left: 11px;
    flex: 1;
    height: 50%;
    border-left: 5px solid var(--color-top);
  }

  .bottom {
    margin-left: 11px;
    flex: 1;
    height: 50%;
    border-left: 5px solid var(--color-bottom);
  }

  .step {
    position: relative;
    border-radius: 15px;
    color: white;
    text-align: center;
    background: var(--color-step);
    padding-top: 3px;
    width: 25px;
    height: 25px;
  }

  .item {
    margin-top: 15px;
    margin-left: 20px;
    flex: 20;
  }

  .item-step {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>